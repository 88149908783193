import React, { useState, useEffect } from "react";
import "./Gallery.css";
import { Footer } from "../../../container/footerSection/Footer";

const Gallery = () => {


  const festivalImages = [
    { url: "/images/fest2.jpg", title: "image 2" },
    { url: "/images/fest3.jpg", title: "image 3" },
    { url: "/images/fest4.jpg", title: "image 4" },
    { url: "/images/fest5.jpg", title: "image 5" },
    { url: "/images/fest6.jpg", title: "image 7" },
    { url: "/images/fest7.jpg", title: "image 8" },
    { url: "/images/fest8.jpg", title: "image 9" },
    { url: "/images/fest9.jpg", title: "image 10" },

  ];

  const TeamImages= [
    { url: "/images/pot2.jpg", title: "image 3" },
    { url: "/images/pot3.jpg", title: "image 4" },
    { url: "/images/pot4.jpg", title: "image 5" },
    { url: "/images/pot5.jpeg", title: "image 7" },
    { url: "/images/gal2.jpg", title: "image 7" },
    { url: "/images/gal11.jpg", title: "image 7" },
    { url: "/images/gal16.jpg", title: "image 7" },
    { url: "/images/pot3.jpg", title: "image 4" },
  

  ];


  const companyLogos = [
    { url: "https://www.bengalurutechsummit.com/img/logo-2-a.png", title: "Company 1" },
    { url: "https://www.indiasoft.org/wp-content/uploads/2024/01/indiasoft-logo-2025.png", title: "Company 2" },
    { url: "/images/gitex.jpeg", title: "Company 3" },
  ];

  const companyeventImages = [
    { url: "https://www.bengalurutechsummit.com/ph-1/3b.jpg", title: "Event 1" },
    { url: "https://www.bengalurutechsummit.com/ph-1/4b.jpg", title: "Event 2" },
    { url: "https://www.bengalurutechsummit.com/ph-1/6b.jpg", title: "Event 3" },
    { url: "https://www.bengalurutechsummit.com/ph-1/12.jpg", title: "Event 4" },

    { url: "https://www.indiasoft.org/wp-content/uploads/2024/09/img-2-min-qnm4tv274j0c0j7561wnvyzngqwxfkq8ancui8qppc.png", title: "Event 5" },
    { url: "https://www.indiasoft.org/wp-content/uploads/2024/09/DSC09048-min-scaled-qitquk77xuay20e0m25ccjmhfahz4agtvkongiu4kg.jpg", title: "Event 4" },

    { url: "https://www.indiasoft.org/wp-content/uploads/2024/09/DSC09152-min-scaled-qitqtgpzysswidzd0l30ftl4h3wk5043q5a9awgnts.jpg", title: "Event 4" },

    { url: "https://www.indiasoft.org/wp-content/uploads/2024/09/img-3-min-qnm4uji027xsee7n7cgyostmwrkgzpf920bgzfqh7k.png", title: "Event 4" },

    { url: "https://www.gitex.com/Uploads//Posts/GitexGallery/galleryimg1810010.jpg", title: "Event 1" },
    { url: "https://www.gitex.com/Uploads//Posts/GitexGallery/galleryimg1810022.jpg", title: "Event 2" },
    { url: "https://www.gitex.com/Uploads//Posts/GitexGallery/galleryimg1810026.jpg", title: "Event 3" },
    { url: "https://www.gitex.com/Uploads//Posts/GitexGallery/galleryimg1810017.jpg", title: "Event 4" },

  ];

  const allImages = [...companyeventImages];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % allImages.length);
    }, 5000); // 5 seconds
    return () => clearInterval(interval);
  }, [allImages.length]);

  // Handler to move to the next slide
  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % allImages.length);
  };

  // Handler to move to the previous slide
  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? allImages.length - 1 : prevSlide - 1
    );
  };

  const renderEventSection = () => {
  

    return (
      <div className="gallery-section" style={{background:"white"}}>
        <h3 className="gallery-section-title">Events</h3>
        {/* Company Logos */}
        <div className="logo-grid">
          {companyLogos.map((logo, index) => (
            <div key={index} className="gallery-item">
              <img src={logo.url} alt={logo.title} className="gallery-image logo-image" />
            </div>
          ))}
        </div>
        {/* Event Images */}
        <div className="gallery-grid">
          {companyeventImages.map((image, index) => (
            <div key={index} className="gallery-item">
              <img src={image.url} alt={image.title} className="gallery-image" style={{height:"100%"}}/>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection = (title, images) => (
    <div className="gallery-section" style={{padding:"50px 40px",background:"white"}}>
      <h3 className="gallery-section-title">{title}</h3>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.url} alt={image.title} className="gallery-image" style={{height:"100%"}} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
   <div className="gallery">
        <div className="hero-section">
          <h1 className="hero-title">Gallery</h1>
        </div>

        <div className="gallery-slider">
          <img
            src={allImages[currentSlide].url}
            alt={allImages[currentSlide].title}
            className="slider-image"
          />
          <button className="slider-button slider-button-left" onClick={handlePrev}>
            &#8249;
          </button>
          <button className="slider-button slider-button-right" onClick={handleNext}>
            &#8250;
          </button>
        </div>

        {renderSection("Festivals", festivalImages)}
        {renderEventSection()} 
        {renderSection("Team Moments", TeamImages)}
      </div>
      <Footer />
    </>
  );


  
};

export default Gallery;
