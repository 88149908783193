import React from "react";
import { Link } from "react-router-dom";
import "./Ecom.css";
import { Footer } from "../../container/footerSection";
import { useEffect } from "react";
const Ecommerce = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="ecommerce_container_update"
        style={{ background: "white" }}
      >
        {/* Development Section */}
        <section className="development_update">
          <div className="development_left_update">
            <img
              src="images/ecomfinalimg.png"
              alt="E-Commerce Development"
              className="development_image_update"
            />
          </div>
          <div className="development_right_update">
            <h1>E-Commerce Website Development</h1>
            <div className="cards_update">
              <h3>Why E-Commerce?</h3>
              <p>
                Step into the future of retail! Selling online unlocks endless
                opportunities to reach new customers, expand into untapped
                markets, and grow your brand like never before.
              </p>
            </div>
            <div className="cards_update">
              <h3>E-Commerce Web Design</h3>
              <p>
                Your website is your digital storefront. With stunning design
                and optimized layouts, we help your business stand out, attract
                more customers, and turn visitors into loyal buyers.
              </p>
            </div>
            <div className="cards_update">
              <h3>Build New Bridges</h3>
              <p>
                Forge lasting connections with your customers by offering a
                personalized, user-friendly, and engaging shopping experience
                that keeps them coming back for more.
              </p>
            </div>
          </div>
        </section>

        {/* E-Commerce Service Description Section */}
        <section className="service_description">
          <div className="service_description_box">
            <h2>
              Transform Your Business with Our E-Commerce Solutions in Just 60
              Days
            </h2>
            <p className="intro_text">
              In today’s digital age, having a strong online presence is crucial
              to the success of any business. We specialize in building
              e-commerce websites that are fast, secure, and optimized for
              growth. Whether you're starting from scratch or looking to revamp
              your current site, we deliver fully functional, feature-packed
              e-commerce stores in under 60 days, giving you a competitive edge
              in the marketplace.
            </p>
            <div className="service-details">
              <div className="service-item">
                <h3>Custom ECommerce Development</h3>
                <p>
                  We create fully customizable, feature-rich websites that match
                  your business needs and help you stand out from the
                  competition.
                </p>
              </div>
              <div className="service-item">
                <h3>Mobile Friendly Design</h3>
                <p>
                  In today’s mobile-first world, it’s critical that your
                  e-commerce website is fully responsive. We ensure your store
                  works flawlessly across all devices, providing a seamless
                  shopping experience for your customers.
                </p>
              </div>
              <div className="service-item">
                <h3>SEO Optimization</h3>
                <p>
                  We make sure your website is SEO-friendly to increase
                  visibility and drive traffic. Our optimization techniques
                  improve your search engine rankings, helping you reach more
                  potential customers.
                </p>
              </div>
              <div className="service-item">
                <h3>Advanced ECommerce Integrations</h3>
                <p>
                  We integrate powerful tools such as payment gateways, ERPs,
                  and marketing automation systems to streamline your business
                  operations and enhance your customer’s shopping experience.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* ERP Integration Section */}
        <section className="erp-integration">
          <h2>Our Services</h2>
          <h1>E-Commerce Website and ERP Integration</h1>
          <div className="erp-cards">
            <div className="erp-card">
              <img src="images/shopify.png" alt="Shopify and Woocommerce" />
              <h3>Shopify and Taskcraft</h3>
              <p>
                It is up to you to decide which one you prefer better and which
                is better for your business. Our web development team offers
                both options.
              </p>
            </div>
            <div className="erp-card">
              <img src="images/parcel.png" alt="Unlimited product stock" />
              <h3>Unlimited product stock</h3>
              <p>
                No matter if you have 5 products or 50000 - we will help you
                optimize your E-Commerce website to be fast, so your customers
                can really enjoy the shopping.
              </p>
            </div>
            <div className="erp-card">
              <img src="images/erp.png" alt="ERP integration" />
              <h3>ERP Integration</h3>
              <p>
                ERP integration and payment gateways. You want your customers to
                enjoy their online shopping experience with intuitive navigation
                and common-sense applications.
              </p>
            </div>
            <div className="erp-card">
              <img src="images/trolly.png" alt="Learn your customer's habits" />
              <h3>Learn your customer’s habits</h3>
              <p>
                Clever insights into your customer’s habits that allow for
                strategizing future business plans.
              </p>
            </div>
            <div className="erp-card">
              <img src="images/rocket.jpg" alt="Be everywhere!" />
              <h3>Be everywhere!</h3>
              <p>
                Location doesn’t play the primary role in establishing your
                customer base - fast and excellent customer service does.
              </p>
            </div>
            <div className="erp-card">
              <img src="images/hand.png" alt="Increased safety" />
              <h3>Increased safety</h3>
              <p>
                We prioritize and provide safety for you and your clients. As
                your online business grows, add another layer of security.
              </p>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="why-choose-us">
          <h2>Why Choose Us?</h2>
          <div className="why-choose-us-content">
            <div className="why-item">
              <h3>Quick Launch, Maximum Impact</h3>
              <p>
                We understand the urgency of getting your e-commerce site up and
                running. Our streamlined development process ensures that your
                website is live and fully operational within 60 days, so you can
                start selling and growing your business without delay.
              </p>
            </div>
            <div className="why-item">
              <h3>Custom Solutions for Your Unique Business</h3>
              <p>
                We know that every business is different. That’s why we offer
                tailored e-commerce solutions that are designed specifically for
                your brand, your target audience, and your business goals. From
                a custom design to advanced integrations, we create a website
                that truly represents your business.
              </p>
            </div>
            <div className="why-item">
              <h3>Fully Mobile-Responsive</h3>
              <p>
                In a world where mobile commerce is booming, we ensure your
                e-commerce store works flawlessly across all devices. Whether
                your customers are browsing on their smartphones or desktop,
                they’ll enjoy a seamless shopping experience.
              </p>
            </div>
            <div className="why-item">
              <h3>SEO-Optimized for Maximum Visibility</h3>
              <p>
                We don’t just build websites; we build websites that drive
                traffic. Our e-commerce sites are SEO-friendly, designed to rank
                higher on search engines and help your business get discovered
                by more potential customers.
              </p>
            </div>
            <div className="why-item">
              <h3>Secure and Scalable Platform</h3>
              <p>
                Security is paramount in the e-commerce world, and we ensure
                your website is protected with the latest technologies, keeping
                your customers’ information safe. Additionally, our websites are
                built to scale, meaning as your business grows, your website can
                grow with you.
              </p>
            </div>
            <div className="why-item">
              <h3>Integrated Payment Gateways</h3>
              <p>
                We seamlessly integrate trusted payment gateways such as PayPal,
                Stripe, and credit card processing, ensuring a smooth and secure
                transaction process for your customers.
              </p>
            </div>
            <div className="why-item">
              <h3>Advanced E-Commerce Features</h3>
              <p>
                Our e-commerce sites come with essential features such as
                inventory management, order tracking, customer dashboards, and
                automated email marketing, allowing you to manage your business
                efficiently while providing an excellent shopping experience for
                your customers.
              </p>
            </div>
            <div className="why-item">
              <h3>Support Every Step of the Way</h3>
              <p>
                Our team doesn’t just build your website and walk away. We offer
                ongoing support to ensure your e-commerce store remains
                up-to-date, secure, and performing at its best.
              </p>
            </div>
          </div>
        </section>

        {/* How We Do It Section */}
        <section className="how-we-do-it">
          <h2>How We Do It</h2>
          <div className="how-we-do-it-content">
            <div className="how-item">
              <div className="icon">
                <img src="images/consult.jpg" alt="Consultation Icon" />
              </div>
              <h3>Consultation & Strategy</h3>
              <p>
                We begin by understanding your business goals, target market,
                and design preferences. We work closely with you to define the
                features and functionality that your website needs.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/design.jpg" alt="Design Icon" />
              </div>
              <h3>Design & Development</h3>
              <p>
                Our expert designers and developers collaborate to bring your
                vision to life with a custom design and a user-friendly
                interface. We build your store with a strong focus on user
                experience, conversion optimization, and mobile responsiveness.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/testing.png" alt="Testing Icon" />
              </div>
              <h3>Testing & Launch</h3>
              <p>
                Before your website goes live, we rigorously test every aspect
                to ensure it’s working perfectly. Once everything is in place,
                we launch your site and ensure it’s running smoothly.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/optimize.png" alt="Optimization Icon" />
              </div>
              <h3>Ongoing Optimization</h3>
              <p>
                After launch, we continuously monitor your site’s performance,
                making improvements and optimizations based on analytics and
                feedback to ensure your business keeps growing.
              </p>
            </div>
          </div>
        </section>

        {/* Our Promise to You Section */}
        <section className="our-promise">
          <h2>Our Promise to You</h2>
          <div className="promise-content">
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/fast.png" alt="Fast Delivery Icon" />
              </div>
              <div className="promise-text">
                <h3>Fast Delivery</h3>
                <p>
                  Get your e-commerce site live in under 60 days, so you can
                  start making sales right away.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img
                  src="images/customize.png"
                  alt="Customized Solutions Icon"
                />
              </div>
              <div className="promise-text">
                <h3>Customized Solutions</h3>
                <p>
                  A website tailored to your business with features that are
                  specifically designed to drive success.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/end.png" alt="End-to-End Service Icon" />
              </div>
              <div className="promise-text">
                <h3>End-to-End Service</h3>
                <p>
                  From design to development, and ongoing support – we’ve got
                  you covered every step of the way.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/price.png" alt="Affordable Pricing Icon" />
              </div>
              <div className="promise-text">
                <h3>Affordable Pricing</h3>
                <p>Get a high-quality e-commerce site that fits your budget.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Ready to Get in Touch Section */}
        <section className="ready-wired">
          <div className="ready-wired-content">
            <h1>
              Ready to Take the{" "}
              <span className="wired-highlight">Next Step?</span>
            </h1>
            <p className="wired-description">
              Whether you're launching your first online store or looking to
              optimize an existing one, we’re here to help. Get in touch with us
              today, and let’s discuss how we can create a website that works
              for you!
            </p>
            <Link to="/contactus">
              <button className="wired-btn">Lets Conenct</button>
            </Link>
          </div>
          <div className="ready-wired-image">
            {/* <img src="images/booking.png" 
    alt="ContactUs" /> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Ecommerce;
