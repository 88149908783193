import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import AOS from "aos";
import Contactimg from "../../../src/assets/images/contactusimg2.png";
import "aos/dist/aos.css";
import { routes } from "../../routes/routes";
import { Link ,useLocation} from "react-router-dom";

import {
  isNameValid,
  isEmailValid,
  isMessageValid,
  isPhoneNumberValid,
  request,
  API,
} from "../../utils";
import { Form_Error_Message } from "../../utils";
import { useForm } from "../../hooks";
import { Button } from "../../components/button";
import "./ContactUs.css";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { Footer } from "../../container/footerSection";

export const ContactUs = () => {
  const [termsValue, setTermsValue] = useState("");
  const [enteredContactFormData, setEnteredContactFormData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []); 
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  const {
    value: fullNameValue,
    valueChangeHandler: fullNameChangeHandler,
    valueBlurHandler: fullNameBlurHandler,
    hasError: fullNameHasError,
    valueIsValid: fullNameIsValid,
    resetValue: fullNameResetValue,
  } = useForm(isNameValid);

  const {
    value: emailValue,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    resetValue: emailResetValue,
  } = useForm(isEmailValid);

  const {
    value: phoneNumberValue,
    valueChangeHandler: phoneNumberChangeHandler,
    valueBlurHandler: phoneNumberBlurHandler,
    hasError: phoneNumberHasError,
    valueIsValid: phoneNumberIsValid,
    resetValue: phoneNumberResetValue,
  } = useForm(isPhoneNumberValid);

  const {
    value: messageValue,
    valueChangeHandler: messageChangeHandler,
    valueBlurHandler: messageBlurHandler,
    hasError: messageHasError,
    valueIsValid: messageIsValid,
    resetValue: messageResetValue,
  } = useForm(isMessageValid);

  let formIsValid = false;

  if (
    fullNameIsValid &&
    emailIsValid &&
    phoneNumberIsValid &&
    messageIsValid &&
    termsValue
  ) {
    formIsValid = true;
  }
  const handleCheckboxValue = (event) => {
    setTermsValue(event.target.value);
  };

 

  const handleCountryCode = (e) => {
    setCountryCode(e);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setEnteredContactFormData({
      your_full_name: fullNameValue,
      your_phone_number: phoneNumberValue,
      country_code: countryCode,
      your_email: emailValue,
      message: messageValue,
      terms: termsValue,
    });

    fullNameResetValue();
    emailResetValue();
    phoneNumberResetValue();
    messageResetValue();
    setTermsValue();
    setCountryCode("91");
  };

  useEffect(() => {
    if (enteredContactFormData) {
      setIsLoading(true);
      contactUsRequestData(enteredContactFormData)
        .then(() => {
          setIsLoading(false);
          return toast("Details Submitted Successfully! ThankYou");
        })

        .catch(() => {
          setIsLoading(false);
          toast("Something went Wrong. Please try again later");
        });
    }
  }, [enteredContactFormData]);
  console.log("return se pehle", countryCode);
  return (
    <>
      <section className="contactus-main-section" id="contactus-section">
        <div className="contactus-container">
          <ToastContainer />
          <div className="align-items-start">
            <div className="" data-aos="fade-left">
              <div className="contactus-title-right">Get In Touch</div>
              <div>
                <form className="contactus-form" onSubmit={submitHandler}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control bd-none md-pd"
                      placeholder="Full name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={fullNameChangeHandler}
                      onBlur={fullNameBlurHandler}
                      value={fullNameValue}
                    />
                  </div>
                  <div className="contactus-mobile-error">
                    {fullNameHasError && !formIsValid && (
                      <span>{Form_Error_Message.invalidFullName}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control md-pd"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                      value={emailValue}
                    />
                  </div>
                  <div className="contactus-error">
                    {emailHasError && !formIsValid && (
                      <span>{Form_Error_Message.invalidBusinessEmail}</span>
                    )}
                  </div>
                  <div className=" mb-3 phone-grid">
                    {/* <div className="phone-Input-Container"> */}
                    <PhoneInput
                      country={"in"}
                      placeholder="Enter country code"
                      enableSearch={true}
                      value={countryCode}
                      onChange={handleCountryCode}
                    />
                    {/* </div> */}
                    <div className="phone-number-input">
                      <input
                        type="text"
                        className="phone-input-num"
                        placeholder="Number"
                        aria-describedby="basic-addon1"
                        onChange={phoneNumberChangeHandler}
                        onBlur={phoneNumberBlurHandler}
                        value={phoneNumberValue}
                      />
                      <div className="contactus-error-ph">
                        {phoneNumberHasError && !formIsValid && (
                          <span>{Form_Error_Message.invalidPhoneNumber}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-group">
                    <textarea
                      id="textareaid"
                      className="form-control"
                      aria-label="With textarea"
                      placeholder="Message"
                      onChange={messageChangeHandler}
                      onBlur={messageBlurHandler}
                      value={messageValue}
                    />
                  </div>
                  <div className="contactus-email-right"></div>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label contactus-form-label mt-4"
                  >
                    The messages should not exceed 300 words
                  </label>
                  <div className="contactus-error contactus-textarea-error">
                    {messageHasError && !formIsValid && (
                      <span>{Form_Error_Message.emptyMessage}</span>
                    )}
                  </div>
                  <div className="mb-3 form-check tnc-text-input">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    value="exampleCheck1"
                    checked={termsValue === "exampleCheck1"}
                    onChange={handleCheckboxValue}
                  />
                  <div className="tnc-text">
                    <label
                      className="form-check-label contactus-check-label"
                      htmlFor="exampleCheck1"
                    >
                      <Link
                        to="/TermsandCondtion"
                      >
                        Terms & conditions
                      </Link>
                     
                    </label>
                  </div>
                  </div>
                  <div className="contactus-left-button contactus-right-button">
                    <Button
                      type="submit"
                      className="contactus-button-left-text"
                      disabled={!formIsValid}
                    >
                      <span className="contactus-button-value">
                        {isLoading ? (
                          <span className="contactus-button-spinner"></span>
                        ) : (
                          "Submit"
                        )}
                      </span>
                      <FaLongArrowAltRight />
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="contactimg">
            <img src={Contactimg} />
          </div>
        </div>
      </section>

      {/* Terms and Conditions Modal
     {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>Terms and Conditions</h2>
            <p>
              By using this service, you agree to the following terms and conditions:
              <br />
              1. You must be 18 years or older.
              <br />
              2. You agree not to misuse the service.
              <br />
              3. You are responsible for maintaining the confidentiality of your account.
              <br />
              4. All data will be securely stored.
              <br />
              5. The service may be subject to changes at any time.
            </p>
            <button className="Agreebtn" onClick={handleAgree}>Agree</button>
          </div>
        </div>
      )} */}
      <Footer />
    </>
  );
};

const contactUsRequestData = (enteredContactFormData) => {
  const response = request({
    method: "post",
    url: API.contactAPI.contactUs,
    userData: enteredContactFormData,
  });
  return response;
};
