export const isNameValid = (name) => {
  const nameRegex   =  /^([a-zA-Z][a-zA-Z][a-zA-Z]+\s)+[a-zA-Z][a-zA-Z][a-zA-Z]+(\s*)+$/;
  let nameValue= nameRegex.test(name)
  return nameValue;
};

export const isCountryCodeValid = (countryCode) => {
    return countryCode?.trim()?.length === 2 && countryCode?.trim() > 0;
};

export const isEmailValid = (email) => {
  const regex= /^[a-zA-Z0-9._-]+@(?:[a-zA-Z0-9-]+\.)+(?:io|com|org|gov\.in|in)$/;

  const isValid = regex.test(email);
  return isValid;
};

export const isPhoneNumberValid = (phoneNumber) => {
  
  if(phoneNumber.length !== 10){
    return false
  } 
  if(isNaN(phoneNumber)){
    return false
  }
  if (/^\d+$/.test(phoneNumber) || phoneNumber === '') {
    return true
  } else {
    return false
  }
  
};

export const isMessageValid = (message) => {
  return message.trim().length >= 10;
};

export const isRadioValueSelected = (value) => {
  return value.trim() !== "";
};


