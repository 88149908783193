import React, { useState } from "react";
import "./Blogs.css";
import Blog1 from "../../assets/blogImg/Blog1.png";
import Blog2 from "../../assets/blogImg/Blog2.png";
import Blog3 from "../../assets/blogImg/Blog3.png";
import Blog4 from "../../assets/blogImg/Blog4.png";
import Blog5 from "../../assets/blogImg/Blog5.png";
import Blog6 from "../../assets/blogImg/Blog6.png";

import {Footer} from "../../container/footerSection/Footer";

const Blog = () => {
  const blogs = [
    {
      img: Blog1,
      title: "UX review presentations",
      content:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
      date: "Monday, 1 Feb 2024",
      listItems: [
        "There are three common grid types used in websites and interfaces: column grid, modular grid, and hierarchical grid.",
        "Column grid involves dividing a page into vertical columns. UI elements and content are then aligned to these columns.",
        "Practice before presenting.",
      ],
      content2:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
    },
    {
      img: Blog2,
      title: "Migrating to Linear 101",
      content:
        "Linear helps streamline software projects, sprints, tasks, and bug tracking. Here's how to get started A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
      date: "Sunday, 4 Sept 2023",
      listItems: [
        "There are three common grid types used in websites and interfaces: column grid, modular grid, and hierarchical grid.",
        "Column grid involves dividing a page into vertical columns. UI elements and content are then aligned to these columns.",
        "Practice before presenting.",
      ],
      content2:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
    },
    {
      img: Blog3,
      title: "Grid system for better Design User Interface",
      content:
        "A grid system is a design tool used to arrange content on a webpage. It creates intersecting points to align and organize page elements effectively.A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
      date: "Wed, 7 Sept 2023",
      listItems: [
        "There are three common grid types used in websites and interfaces: column grid, modular grid, and hierarchical grid.",
        "Column grid involves dividing a page into vertical columns. UI elements and content are then aligned to these columns.",
        "Practice before presenting.",
      ],
      content2:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
    },
    {
      img: Blog4,
      title: "Grid system for better Design User Interface",
      content:
        "A grid system is a design tool used to arrange content on a webpage. It creates intersecting points to align and organize page elements effectively.How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
      date: "Sat, 1 Jul 2023",
      listItems: [
        "There are three common grid types used in websites and interfaces: column grid, modular grid, and hierarchical grid.",
        "Column grid involves dividing a page into vertical columns. UI elements and content are then aligned to these columns.",
        "Practice before presenting.",
      ],
      content2:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
    },
    {
      img: Blog5,
      title: "Grid system for better Design User Interface",
      content:
        "A grid system is a design tool used to arrange content on a webpage. It creates intersecting points to align and organize page elements effectively.",
      date: "Sunday, 1 Jan 2023",
    },
    {
      img: Blog6,
      title: "Grid system for better Design User Interface",
      content:
        "A grid system is a design tool used to arrange content on a webpage. It creates intersecting points to align and organize page elements effectively.How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
      date: "Sunday, 1 Jan 2023",
      listItems: [
        "There are three common grid types used in websites and interfaces: column grid, modular grid, and hierarchical grid.",
        "Column grid involves dividing a page into vertical columns. UI elements and content are then aligned to these columns.",
        "Practice before presenting.",
      ],
      content2:
        "How do you create compelling presentations that wow your colleagues and impress your managers. A grid system is a design tool used to arrange content on a webpage. It is a series of vertical and horizontal lines that create a matrix of intersecting points, which can be used to align and organize page elements. Grid systems are used to create a consistent look and feel across a website, and can help to make the layout more visually appealing and easier to navigate.",
    },
  ];

  const [selectedBlog, setSelectedBlog] = useState(blogs[0]);
  const [isContentVisible, setContentVisible] = useState(false);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    setContentVisible(true);
  };

  return (
    <>
      <div className="container" style={{background:"white"}}>
        <div className="blog-page-container">
          {/* Sidebar Section */}
          <div className="sidebar">
            <h5 className="recent-posts-title">Recent blog posts</h5>
            {blogs.map((blog, index) => (
              <div
                key={index}
                className={`sidebar-item ${
                  selectedBlog.title === blog.title ? "active" : ""
                }`}
                onClick={() => handleBlogClick(blog)}
              >
                <img className="blog-small-img" src={blog.img} />
                <div className="sidebar-data">
                  <i class="fa-solid fa-calendar-days"></i>{" "}
                  <p className="blog-date">{blog.date}</p>
                </div>
                <div className="sidebar-data">
                  <h6 className="blog-title">
                    {blog.title} <i class="fa-solid fa-arrow-right"></i>
                  </h6>
                </div>
               
                <hr />
              </div>
            ))}
          </div>

          {/* Content Section */}
          <div className={`content ${isContentVisible ? "active" : ""}`}>
            <p className="blog-date" style={{marginLeft: "60px"}}>{selectedBlog.date}</p>
            <h2 className="blog-title-large" style={{marginLeft: "60px"}}>{selectedBlog.title}</h2>
            <img className="blog-selected-img" src={selectedBlog.img} />
            <p className="blog-content">{selectedBlog.content}</p>
            {selectedBlog.listItems && (
              <ul className="blog-list-container">
                {selectedBlog.listItems.map((item, index) => (
                  <div className="blog-lists">
                    <i class="fa-regular fa-circle-check blog-list-icon"></i>
                    <li key={index}>{item}</li>
                  </div>
                ))}
              </ul>
            )}
            <p className="blog-content">{selectedBlog.content2}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Blog;
