import React from "react";
import "./Button.css";

export const Button = ({ children, onClick, className, type, disabled }) => {
  return (
    <button
      className={className ? className : "element-button"}
      onClick={onClick}
      type={type ? type : "button"}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
