import React from "react";

import svg1 from "../../../assets/hand.png"
import { Button } from "../../../components/button";
import svg2 from "../../../assets/search.png"
import "./AboutContactUs.css";
import { Link, useNavigate } from "react-router-dom";
import { useScroll } from '../../../context/ScrollContext';

export const AboutContactUs = () => {
  const navigate = useNavigate()
  const {scrollToSection} = useScroll();

  const handleJoinUs = () => {
    navigate("/staffAugmentation")
  }

  const handleOurService = () => {
    scrollToSection("ourservice")
  }

  return (
    <div className="about-contact-us-main-div">
      <div className="container">
        <div className="row about-us-main-row" >
          <div className="col about-us-contact-first-column">
            <div className="about-us-icons">
            <img className="about-us-icons-image" src={svg1} alt="svg1"></img>
            </div>
            <div>
              <h2 className="about-us-know-more">Want to know more?</h2>
              <p className="about-us-know-content">How we build pillars to achieve heights!</p>
              <Button className="about-us-know-button" onClick={handleOurService} >Explore Our Services</Button>
            </div>
          </div>
          <div className="col about-us-contact-first-column">
          <div className="about-us-icons">
          <img className="about-us-icons-image-second" src={svg2} alt='svg2'></img>
          </div>
            <div>
              <h2 className="about-us-know-more">Want to join us?</h2>
              <p className="about-us-know-content">Let’s fill the gaps with IT!</p>
              <Button className="about-us-join-button" onClick={handleJoinUs} > Join Us</Button>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};
