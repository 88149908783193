import React from "react";

import { Link, useParams } from "react-router-dom";
import careersFormUploadContent from "../../../../constants/constants.json"
import "./CareersFormUploadContent.css";
import { IoIosArrowForward } from "react-icons/io";
import { routes } from "../../../../routes/routes";
export const CareersFormUploadContent = () => {
  const { contentId } = useParams();

  const content = careersFormUploadContent[0].find((el) => el.id === "careers");
  // console.log(contentId);
  // console.log(content);

  return (
    <>
      <div className="hire-developer-title-left">{content?.title}</div>
      <div className="hire-developer-left-body">{content?.subTitle}</div>
      <div className="hire-developer-left-subbody">{content?.body}<Link to="/contactus" style={{color:"black", textDecoration:"none"}}><br></br>
      <b>Apply here</b></Link><span style={{cursor:"pointer"}}><Link style={{color:"orange"}} to="/contactus">< IoIosArrowForward/></Link></span>
</div>
    </>
  );
};
