import React, { useState } from "react";
import "../servicepage/style.css";
import { Footer } from "../../../container/footerSection/Footer";

const services = [
  {
    id: 1,
    service: "Software Development",
    description:
      "Software development encompasses the creation of custom software solutions, from desktop and mobile apps to complex enterprise systems. This service covers the entire software lifecycle, including design, development, testing, and deployment, to deliver scalable, secure, and high-quality software solutions tailored to meet specific business needs.",
  },
  {
    id: 2,
    service: "Custom Application Development",
    description:
      "Custom application development focuses on creating software applications that are specifically tailored to the unique requirements of a business. This service ensures that the final product is fully aligned with business goals, providing a competitive edge through bespoke solutions.",
  },
  {
    id: 3,
    service: "Product Engineering",
    description:
      "Product engineering involves designing, developing, and maintaining products throughout their lifecycle. This includes hardware and software engineering, as well as product testing and optimization. The goal is to innovate and bring high-quality products to market efficiently while addressing both customer needs and business objectives.",
  },
  {
    id: 4,
    service: "Infrastructure Management",
    description:
      "Infrastructure management focuses on maintaining the IT infrastructure that supports business operations, ensuring it is reliable, secure, and scalable. This service includes network management, server management, database administration, and disaster recovery planning to minimize downtime and optimize IT performance.",
  },
  {
    id: 5,
    service: "Cloud & Infrastructure",
    description:
      "Cloud & infrastructure services help businesses transition to and optimize cloud environments, offering scalable and cost-effective solutions. This service includes cloud migration, management of cloud resources, and integration with existing infrastructure, ensuring a seamless and efficient IT environment.",
  },
  {
    id: 6,
    service: "DevOps Solutions",
    description:
      "DevOps solutions streamline the development and IT operations process by automating workflows and improving collaboration between development, testing, and operations teams. This service focuses on enhancing the speed and reliability of software delivery through continuous integration, continuous deployment, and automated testing.",
  },
  {
    id: 7,
    service: "Salesforce Development",
    description:
      "Salesforce development involves customizing the Salesforce platform to meet the unique needs of businesses. This includes configuring Salesforce CRM, integrating with other enterprise systems, and developing custom solutions to optimize customer relationships, sales, and marketing efforts.",
  },
  {
    id: 8,
    service: "Managed AWS Services",
    description:
      "Managed AWS services include comprehensive management of Amazon Web Services (AWS) infrastructure. This service covers the provisioning, configuration, monitoring, and security of AWS resources, allowing businesses to leverage cloud capabilities while focusing on core business operations.",
  },
  {
    id: 9,
    service: "Enterprise Solutions",
    description:
      "Enterprise solutions help businesses integrate and streamline their operations with customized software and ERP systems. These solutions ensure that all aspects of an enterprise, from finance to supply chain management, work cohesively, improving productivity and decision-making across the organization.",
  },
  {
    id: 10,
    service: "ERP Solutions",
    description:
      "ERP solutions enable businesses to manage and automate their core processes, including accounting, inventory, and customer relations, through an integrated software system. This service optimizes business operations, enhances data accuracy, and improves overall efficiency.",
  },
  {
    id: 11,
    service: "On-demand Solutions",
    description:
      "On-demand solutions provide flexible and scalable services tailored to meet immediate business needs. Whether for software, staffing, or IT resources, these solutions ensure that businesses can rapidly scale or modify operations in response to market conditions.",
  },
  {
    id: 12,
    service: "Mobility Solutions",
    description:
      "Mobility solutions provide businesses with the tools to create mobile apps and services that support employees, customers, and operations on the go. This includes custom mobile app development, mobile strategy consulting, and integrating mobile solutions into existing business processes.",
  },
  {
    id: 13,
    service: "Staff Augmentation",
    description:
      "Staff augmentation services provide businesses with additional expertise by temporarily adding skilled professionals to a team. This service helps businesses quickly address resource shortages, scale projects, and manage peak workloads without the long-term commitment of hiring full-time employees.",
  },
  {
    id: 14,
    service: "Application Services",
    description:
      "Application services focus on the design, development, deployment, and maintenance of software applications. These services ensure that business applications are performing optimally, meeting user needs, and adapting to changes in technology and business requirements.",
  },
  {
    id: 15,
    service: "API Development",
    description:
      "API development services help businesses create, integrate, and manage application programming interfaces (APIs) that allow different software systems to communicate with each other. APIs are essential for connecting platforms, enhancing user experience, and enabling new functionalities.",
  },
  {
    id: 16,
    service: "Testing Solutions",
    description:
      "Testing solutions provide quality assurance through systematic testing to identify and resolve defects in software applications. These services include manual and automated testing, performance testing, and security testing to ensure that applications are robust, secure, and error-free before deployment.",
  },
  {
    id: 17,
    service: "Database Solutions",
    description:
      "Database solutions include the design, implementation, and management of databases to support business applications. This service ensures that databases are scalable, secure, and optimized for performance, handling large volumes of data while ensuring quick retrieval and integrity.",
  },
  {
    id: 18,
    service: "Back-End Solution",
    description:
      "Back-end solutions involve the creation and management of the server-side components of a web or mobile application. This includes building APIs, databases, and server infrastructure to ensure that front-end applications function seamlessly and efficiently with back-end processes.",
  },
  {
    id: 19,
    service: "Custom Mobile Development",
    description:
      "Custom mobile development focuses on building mobile applications tailored to a business’s specific needs. These applications are designed to provide seamless experiences across iOS and Android platforms, enhancing user engagement and supporting business operations on the go.",
  },
  {
    id: 20,
    service: "Web Development",
    description:
      "Web development services include the creation of websites and web applications designed to meet the unique needs of businesses. This service covers front-end and back-end development, responsive design, e-commerce solutions, and integration with other software to create interactive and high-performance websites.",
  },
];

export const ServicePage = () => {
  const [selectedService, setSelectedService] = useState(null);

  const openModel = (service) => {
    setSelectedService(service);
  };

  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <>
      <div className="services-container">
        <section
          className="about-us"
          style={{
            backgroundImage: "url(/images/nocode.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginTop: "40px",
            marginBottom: "40px",
            padding: "100px 20px", // Base padding for mobile
            paddingBottom: "50px",
          }}
        >
          <div
            className="about-us-content"
            style={{
              // backgroundColor: "rgba(0, 0, 0, 0.6)", // semi-transparent black background
              color: "white",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              maxWidth: "800px", // Center content on larger screens
              margin: "auto",
            }}
          >
            <h2
              style={{
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              What's Our Expertise?
            </h2>
            <p
              style={{
                color: "white",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
              }}
            >
              We are a leading provider of innovative software solutions,
              helping businesses thrive in the digital age. Our expert team
              delivers tailored services across various industries, driving
              efficiency, growth, and transformation. Leveraging cutting-edge
              technologies, we create custom applications and cloud solutions
              that enhance your competitive advantage.
            </p>
          </div>
        </section>

        <div>
          <h2>Our Services</h2>
          <p>
            We provide a diverse suite of bespoke services designed to address
            the specific needs of your business. Our expert team delivers
            high-performance solutions that drive innovation, operational
            efficiency, and scalable growth. From custom-built applications to
            advanced cloud solutions, we empower your business to excel in the
            digital age.
          </p>

          <div className="services-list">
            {services.map((service, index) => (
              <div key={index} className="service-item">
                <div className="service-image">
                  <img
                    src={`/images/${service.service
                      .replace(/\s+/g, "_")
                      .toLowerCase()}.png`}
                    onError={(e) =>
                      (e.target.src = "/images/default-image.png")
                    }
                    alt={service.service}
                    onClick={() => openModel(service)} // Open model on image click
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    color: "#F28C04",
                    marginBottom: "1rem",
                    height: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {service.service}
                </h3>
                {/* <p>{service.description}</p> */}
              </div>
            ))}
          </div>
        </div>

        <div className="service-container">
          <section className="service-partners">
            <h2>Our Service Partners</h2>
            <div className="partners-container">
              
                <img className="partnerlogo" src="/images/salsforce.svg" alt="Partner 1" />
               
              
                <img className="partnerlogo" src="/images/zoho.svg" alt="Partner 2" />
               
             
                <img className="partnerlogo" src="/images/bigcommerce.svg" alt="Partner 4" />
                
                <img className="partnerlogo" src="/images/amazon-removebg.png" alt="Partner 4" />
                
                <img className="partnerlogo" src="/images/shopify-2.svg" alt="Partner 4" />
               
            </div>
            <div className="partners-container">
              
              <img className="partnerlogo-2" src="/images/shakehand.webp" alt="Partner 1" />
             
            
              <img className="partnerlogo-2" src="/images/microfix360.png" alt="Partner 2" />
             
           
              <img className="partnerlogo-2" src="/images/builder.png"  alt="Partner 4" />
              
             
             
          </div>
            <div className="partners-description">
              <p>
                We collaborate with renowned industry leaders to deliver
                exceptional, best-in-class solutions to our clients. By
                partnering with trusted experts in their respective domains, we
                ensure access to a comprehensive suite of services designed to
                address your unique business needs with precision and
                excellence.
              </p>
            </div>
          </section>

          <section className="choose-us">
            <h2>Why Choose Us?</h2>
            <div className="cards-container">
              <div className="card">
                <h3>Expertise</h3>
                <p>
                  With extensive experience, our team excels in delivering
                  tailored, high-quality solutions to meet your unique needs.
                </p>
              </div>
              <div className="card">
                <h3>Reliability</h3>
                <p>
                  We are dedicated to delivering dependable services with
                  uncompromising quality and a focus on customer satisfaction.
                </p>
              </div>
              <div className="card">
                <h3>Support</h3>
                <p>
                  Our 24/7 customer support ensures you receive exceptional care
                  whenever you need it.
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* Modal for service description */}
        {selectedService && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="modal-close" onClick={closeModal}>
                &times;
              </span>
              <img
                src={`/images/${selectedService.service
                  .replace(/\s+/g, "_")
                  .toLowerCase()}.png`}
                onError={(e) => (e.target.src = "/images/default-image.png")}
                alt={selectedService.service}
                style={{
                  width: "50%",
                  height: "50%",
                  textAlign: "center",
                  marginLeft: "25%",
                }}
              />
              <h3>{selectedService.service}</h3>
              <p>{selectedService.description}</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};
