import React, { useState, useRef } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Collapse } from "react-bootstrap";

import { routes } from "../../routes/routes";
import { Button } from "../../components/button";
import { SecondHoverContent } from "./secondHoverContent/SecondHoverContent";
import TaskCraftLogo from "../../assets/TaskCraft_logo.png";
import "./Header.css";

export const Header = () => {
  const navigate = useNavigate();
  const [color, setColor] = useState(false);
  const [show, setShow] = useState(false);
  const [isServicesAccordionOpen, setServicesAccordionOpen] = useState(false);

  const navRef = useRef();

  const headerNavigateHandler = () => {
    navigate("/staffAugmentation");
  };

  const services = [
    {
      id: "s1",
      label: "Software Development",
      subTitle: [
        {
          id: "st1",
          title: "Custom Application Development",
          to: "customApplicationDevelopment",
        },
        {
          id: "st2",
          title: "Custom Mobile Development",
          to: "customMobileDevelopment",
        },
        {
          id: "st3",
          title: "Web Development",
          to: "webDevelopment",
        },
        {
          id: "st4",
          title: "Product Engineering",
          to: "productEngineering",
        },
      ],
    },
    {
      id: "s2",
      label: "Infrastructure Management",
      subTitle: [
        {
          id: "st5",
          title: "Cloud & Infrastructure",
          to: "cloudAndInfrastructure",
        },
        {
          id: "st6",
          title: "DevOps Solutions",
          to: "devOpsSolutions",
        },
        {
          id: "st7",
          title: "Salesforce Development",
          to: "salesforceDevelopment",
        },
        {
          id: "st8",
          title: "Product Engineering",
          to: "managedAWSService",
        },
      ],
    },
    {
      id: "s3",
      label: "Enterprise Solutions",
      subTitle: [
        {
          id: "st13",
          title: "API Development",
          to: "APIDevelopment",
        },
        {
          id: "st14",
          title: "Testing Solutions",
          to: "testingSolutions",
        },
        {
          id: "st15",
          title: "Database Solutions",
          to: "databaseSolutions",
        },
        {
          id: "st16",
          title: "back-endSolutions",
          to: "staffAugumentation",
        },
      ],
    },
    {
      id: "s4",
      label: "Application Services",
      subTitle: [
        {
          id: "st9",
          title: "ERP Solutions",
          to: "ERPSolutions",
        },
        {
          id: "st10",
          title: "On-demand Solutions",
          to: "onDemandSolutions",
        },
        {
          id: "st11",
          title: "Mobility Solutions",
          to: "mobilitySolutions",
        },
        {
          id: "st12",
          title: "Back-end Solutions",
          to: "back-endSolutions",
        },
      ],
    },
  ];

  const products = [
    { id: "p1", label: "No Code / Low Code", to: "noCodeLowCode" },
    { id: "p2", label: "AI / ML", to: "aiMl" },
    { id: "p3", label: "Ecommerce", to: "ecommerce" },
  ];

  const heading = [
    { label: "Services", value: "Services" },
    { label: "About", value: "About Us" },
    { label: "Get In Touch", value: "Reach Out" },
    { label: "Careers", value: "Careers" },
    { label: "Team", value: "Team" },
    { label: "Events", value: "Events" },
    { label: "Products", value: "Products" },
    { label: "Blogs", value: "Blogs" },
    { label: "Gallery", value: "Gallery" },
  ];

  const showNavbar = () => {
    navRef.current?.classList?.toggle("responsive_nav");
    navRef.current?.classList?.remove("close_responsive_nav");
  };

  const closeNavbar = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    navRef.current?.classList?.toggle("close_responsive_nav");
    navRef.current?.classList?.remove("responsive_nav");
  };

  const toggleServicesAccordion = () => {
    setServicesAccordionOpen(!isServicesAccordionOpen);
  };
  const handleChangeColor = () => {
    setColor(window.scrollY >= 80);
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    setShow(false);
  };

  window.addEventListener("scroll", handleChangeColor);
  const location = useLocation();
  const isActivePage =
    location.pathname.includes("AIMl") ||
    location.pathname.includes("nocodelowcode") ||
    location.pathname.includes("Ecommerce");

  return (
    <div>
      <header className={color ? "header-bg" : "header-main-div"}>
        <div className="header-sub-div">
          <Link
            to={routes.general.home}
            onClick={handleLogoClick}
            className={color ? "banner-container-bg" : "banner-container"}
          >
            <img
              className="banner-tagline"
              src={TaskCraftLogo}
              alt="taskCraft logo"
            />
          </Link>
          <nav ref={navRef}>
            <div id="header-navbar-one"></div>
            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
              >
                <Link
                  to={routes.general.aboutUs}
                  className="heading-other-names"
                  onClick={closeNavbar}
                >
                  {heading[1].label}
                </Link>
              </Button>
            </div>
            <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.aboutUs}
                className={({ isActive }) =>
                  isActive ? "dropdown-link" : "heading-first-name"
                }
              >
                {heading[1].label}
              </NavLink>
            </div>
            <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.servicepage}
                // className={({ isActive }) =>
                //   isActive ? "dropdown-link" : "heading-first-name"
                // }

                className={({ isActive }) =>
                  isActive || isActivePage
                    ? "dropdown-link"
                    : "heading-first-name"
                }
              >
                {heading[0].label}
              </NavLink>
            </div>

            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
                onClick={toggleServicesAccordion}
              >
                <span className="heading-other-names">
                  {heading[6].label}
                  <IoIosArrowDown />
                </span>
              </Button>
              <div
                className={color ? "main-navbar" : "main-navbar-bg"}
                onClick={() => setShow(!show)}
              >
                <NavLink
                  onClick={handleLogoClick}
                  // to={routes.general.servicepage}
                  className="heading-first-name"
                >
                  {heading[6].label}
                  <IoIosArrowDown />
                </NavLink>

                {show && <SecondHoverContent setShow={setShow} />}
              </div>
            </div>

            <Collapse in={isServicesAccordionOpen}>
              <div className="accordions-body">
                {products.map((item) => (
                  <>
                    <Link to={item.to} className="link-tags" key={item.id}>
                      <option
                        className="industry-options"
                        onClick={closeNavbar}
                      >
                        {item.label}
                      </option>
                    </Link>
                  </>
                ))}
              </div>
            </Collapse>

            <div className="services-toggle-button">
              <Button
                className={
                  color ? "accordions-main-button" : "accordions-button-bg"
                }
              >
                <Link
                  to={routes.general.contactUs}
                  className="heading-other-names"
                  onClick={closeNavbar}
                >
                  {heading[2].label}
                </Link>
              </Button>
            </div>

            {/* product and hover end  */}

            {/* {/ Team and Award  /} */}
            <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.Team}
                className={({ isActive }) =>
                  isActive ? "dropdown-link" : "heading-first-name"
                }
              >
                {heading[4].label}
              </NavLink>
            </div>

            {/* {/ Team and Award  /} */}
            <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.gallery}
                className={({ isActive }) =>
                  isActive ? "dropdown-link" : "heading-first-name"
                }
              >
                {heading[8].label}
              </NavLink>
            </div>

            {/* <div>
              <NavLink
                to={routes.general.events}
                className={({ isActive }) =>
                  isActive ? "dropdown-link" : "heading-first-name"
                }
              >
                {heading[5].label}
              </NavLink>
            </div> */}

            <div>
              <NavLink
                to={routes.general.careers}
                className={({ isActive }) =>
                  isActive ? "dropdown-link active" : "heading-first-name"
                }
              >
                {heading[3].label}
              </NavLink>
            </div>
            <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.Blog}
                className={({ isActive }) =>
                  isActive ? "dropdown-link active" : "heading-first-name"
                }
              >
                {heading[7].label}
              </NavLink>
            </div>

            {/* <div>
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.ai}
                className={({ isActive }) =>
                  isActive ? "dropdown-link active" : "heading-first-name"
                }
              >
                AI
              </NavLink>
            </div> */}
            {/* <div>
              <NavLink
                onClick={handleLogoClick}
                to={"/HomeTestimonials"}
                className={({ isActive }) =>
                  isActive ? "dropdown-link active" : "heading-first-name"
                }
              >
                Testimonials
              </NavLink>
            </div> */}
            <div className="getintouch-btn">
              <NavLink
                onClick={handleLogoClick}
                to={routes.general.contactUs}
                className={({ isActive }) =>
                  isActive
                    ? "dropdown-linksss active"
                    : "heading-first-name  Gethover"
                }
              >
                {heading[2].label}
              </NavLink>
            </div>
          </nav>
        </div>
        <Button
          className={color ? "nav-btn" : "nav-btn-bg"}
          onClick={showNavbar}
        >
          {/* fix */}
          <FaBars />
        </Button>
      </header>
    </div>
  );
};
