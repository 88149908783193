
import React, { createContext, useContext, useState } from 'react';

const IndustriesContext = createContext();

export function IndustriesProvider({ children }) {
  const [selectedIndustry, setSelectedIndustry] = useState("Healthcare");

  return (
    <IndustriesContext.Provider value={{ selectedIndustry, setSelectedIndustry }}>
      {children}
    </IndustriesContext.Provider>
  );
}

export function useIndustries() {
  return useContext(IndustriesContext);
}
