// RadioContext.js

import React, { createContext, useContext, useState } from 'react';

// Create a context
const RadioContext = createContext();

// Create a provider component
export function RadioProvider({ children }) {
  const [selectedRadioId, setSelectedRadioId] = useState(null);

  const handleRadioClick = (radioId) => {
    setSelectedRadioId(radioId);
  };

  return (
    <RadioContext.Provider value={{ selectedRadioId, handleRadioClick }}>
      {children}
    </RadioContext.Provider>
  );
}

// Create a custom hook for using the context
export function useRadio() {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error('useRadio must be used within a RadioProvider');
  }
  return context;
}
