import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./TechiesPractice.css";
import RorPng from '../../../assets/RoR.png'

const react_app = [
  {
    id: "r1",
    title: "React Native",
    url: "https://www.ipraxa.com/blog/wp-content/uploads/2018/08/Technology-Stack-for-Mobile-App.png",
  },
//   {
//   id: "r2",
//   title: "React JS",
//   url: "https://graffersid.com/wp-content/uploads/2023/02/react_border.png",
// },
];

const web_frontend = [
  {
    id: "w1",
    title: "React JS",
    url: "https://images.unsplash.com/photo-1602265585142-6b221b9b2c24?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  // {
  //   id: "w2",
  //   title: "Angular",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171867.png",
  // },
  // {
  //   id: "w3",
  //   title: "Redux",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171868.png",
  // },
  // {
  //   id: "w4",
  //   title: "Type Script",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171869.png",
  // },
  // {
  //   id: "w5",
  //   title: "BootStrap",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171873.png",
  // },
  // {
  //   id: "w6",
  //   title: "HTML",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171874.png",
  // },
  // {
  //   id: "w7",
  //   title: "CSS",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171882.png",
  // },
];

const backend = [
  {
    id: "b1",
    title: "NodeJS",
    url: "https://media.licdn.com/dms/image/C4E12AQFEBcTaSSxlFQ/article-cover_image-shrink_600_2000/0/1652682212359?e=2147483647&v=beta&t=-wclt65BzEhXVxVK9b_do_90Od3uqdQdyHrqCOQ8mlM",
  },
  // {
  //   id: "b2",
  //   title: "Express.JS",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171871.png",
  // },
  // {
  //   id: "b3",
  //   title: "NestJS",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171872.png",
  // },
  // {
  //   id: "b4",
  //   title: "Ruby on Rails",
  //   url: RorPng,
  // },
];

const database = [
  {
    id: "d1",
    title: "MongoDB",
    url: "https://miro.medium.com/v2/resize:fit:1200/1*JxbgCyYIbL00f9o_9VZ-PA.png",
  },
  // {
  //   id: "d2",
  //   title: "PostgreSQL",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171878.png",
  // },
  // {
  //   id: "d3",
  //   title: "MySQL",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171889.png",
  // },
  // {
  //   id: "d4",
  //   title: "MariaDB",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171888.png",
  // },
];

const devops = [
  {
    id: "de1",
    title: "AWS",
    url: "https://i.ytimg.com/vi/5gfwSMsL-O8/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCsk2mAOmI60j9Zbdte4SO359c8CQ",
  },
  // {
  //   id: "de2",
  //   title: "Google Cloud",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171880.png",
  // },
  // {
  //   id: "de3",
  //   title: "Azure",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171881.png",
  // },
  // {
  //   id: "de4",
  //   title: "Jenkins",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171883.png",
  // },
  // {
  //   id: "de5",
  //   title: "Docker",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171887.png",
  // },
  // {
  //   id: "de6",
  //   title: "Ansible",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171886.png",
  // },
  // {
  //   id: "de7",
  //   title: "Kubernetes",
  //   url: "https://graffersid.com/wp-content/uploads/2023/02/Group-171884.png",
  // },
];

export const TechiesPractice = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <section>
      <div className="tech-heading">
        <h3 className="element-heading">
          Technology Stack
        </h3>
        <div className="element-divider" style={{background: 'none'}}>
          <span className="element-divider-separator"></span>
        </div>
        <p className="element-heading-para">We turn your vision into reality by delivering cutting-edge tech solutions powered by <br/>
          the latest technologies and innovative tools</p>
      </div>
      <div className="card-container">
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Mobile App</p>
            </div>
            <ul className="image-row card-one">
              {react_app.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt=""  
                    style={{
                      width: "100%", 
                      height: "20vh", 
                      objectFit: "cover",
                      marginTop:"-25px" 
                    }} 
                  />
                    {/* <p className="card-content-para">{data.title}</p> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Frontend</p>
            </div>
            <ul className="image-row">
              {web_frontend.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt=""
                    style={{
                      width: "100%", 
                      height: "20vh", 
                      objectFit: "cover",
                      marginTop:"-25px" 
                    }} 
                    />
                    {/* <p className="card-content-para">{data.title}</p> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="techies-card" data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>BackEnd</p>
            </div>
          </div>
          <ul className="image-row">
            {backend.map((data) => (
              <li className="image-item" key={data.id}>
                <a href="/">
                  <img src={data.url} alt="" 
                   style={{
                    width: "100%", 
                    height: "20vh", 
                    objectFit: "cover",
                    marginTop:"-25px" 
                  }} 
                  />
                  {/* <p className="card-content-para">{data.title}</p> */}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="card-container">
        <div className="techies-card" style={{marginRight: "30px"}} data-aos="zoom-in">
          <div className="card-content">
            <div className="sub-title">
              <p>Database</p>
            </div>
            <ul className="image-row">
              {database.map((data) => (
                <li className="image-item" key={data.id}>
                  <a href="/">
                    <img src={data.url} alt="" 
                    style={{
                      width: "100%", 
                      height: "20vh", 
                      objectFit: "cover",
                      marginTop:"-25px" 
                    }} 
                    />
                    {/* <p className="card-content-para">{data.title}</p> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={"techies-card card-right"} data-aos="zoom-in" style={{maxWidth: "350px", marginLeft: "0px"}}>
          <div className="card-content">
            <div className="sub-title">
              <p>Devops</p>
            </div>
            <ul className="image-row card-fifth">
              {devops.map((data) => (
                <li className="image-item card-fifth" key={data.id}>
                  <a href="/" >
                    <img src={data.url} alt="" 
                    style={{
                      width: "100%", 
                      height: "20vh", 
                      objectFit: "cover",
                      marginTop:"-25px" 
                    }} 
                    />
                    {/* <p className="card-content-para">{data.title}</p> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
