import React from "react";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaSkype,
} from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

import iso from "../../assets/iso_27001_02.png";
import cmmi5 from "../../assets/CMMI_Level_5.png";
import "./Footer.css";
import { useScroll } from "../../context/ScrollContext";
import { useIndustries } from "../../context/IndustriesContext";
import { useRadio } from "../../context/RadioContext";
import { routes } from "../../routes/routes";

const first_column = [
  { id: "s1", title: "Sports" },
  { id: "s2", title: "Fintech" },
  { id: "f1", title: "Healthcare" },
  { id: "s3", title: "On-Demand" },
  { id: "f3", title: "E-commerce" },
  { id: "f2", title: "Energy & Gas" },
  { id: "s4", title: "Marketing & Advertising" },
  { id: "f4", title: "Transportation & Logistics" },
];

const center_first_column = [
  {
    id: "cf3",
    title: "Enterprise Solution",
  },
  {
    id: "cf4",
    title: "Application Services",
  },
  {
    id: "cf5",
    title: "Service-based Model",
  },
  {
    id: "cf6",
    title: "Product-based Model",
  },
  { id: "cf1", title: "Software Development" },
  {
    id: "cf2",
    title: "Infrastructure Management",
  },
 
  
];

const center_second_column = [
  { id: "cs13", title: "PHP" },
  { id: "cs11", title: "Java" },
  { id: "cs5", title: "JQuery" },
  { id: "cs12", title: "Shopify" },
  { id: "cs4", title: "Node.js" },
  { id: "cs2", title: "React.js" },
  { id: "cs3", title: "React Native" },
  { id: "cs1", title: "Ruby on Rails" },
  
];

const center_third_column = [
  { id: "cs15", title: ".Net" },
  { id: "cs8", title: "MERN" },
  { id: "cs7", title: "MEAN" },
  { id: "cs14", title: "Flutter" },
  { id: "cs16", title: "C, C++" },
  { id: "cs6", title: "HTML5" },
  { id: "cs10", title: "DevOps" },
  { id: "cs9", title: "Manual QA" },
  
];

export const Footer = () => {
  const { setSelectedIndustry } = useIndustries();
  const { scrollToSection } = useScroll();
  const navigate = useNavigate();
  const { handleRadioClick } = useRadio();

  const navigateToAboutUs = () => {
    navigate("/aboutUs");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = (sectionId) => {
    handleRadioClick(sectionId);
    setSelectedIndustry(sectionId);
    scrollToSection("scrollId");
  };

  return (
    <section className="footer-main-section">
      <div className="container text-center " style={{ marginRight: "20px" }}>
        <div className="row align-items-start">
          <div className="footer-top-section my-4">
            <div className="col">
              {/* <div className="footer-first-logo">
                <img src={TaskCraftLogo} alt="" />
              </div> */}
            </div>
            <div className="col">
              <div className="footer-second-logo"></div>
            </div>
          </div>
        </div>
        <div className="row align-items-center" data-aos="fade-right">
          <div
            className="col footer-margin-center"
            style={{ marginRight: "-70px" }}
          >
            <div className="footer-subcol-center">
              <div className="col">
                <div className="footer-left-heading footer-left-subheading">
                  INDUSTRIES
                </div>
                <div className="footer-subcol-left">
                  <div className="col footer-subcolumn-ul ">
                    <ul>
                      {first_column.map((item) => (
                        <li
                          className="center-listitems"
                          key={item.id}
                          onClick={(e) => {
                            e.preventDefault();
                            handleScroll(item.title);
                          }}
                        >
                          <Link to="/servicepage" className="footer-listitems-parent-left">
                            {item.title}   
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-left-heading">Models</div>
                <div className="footer-subcolumn-ul">
                  <ul>
                    {center_first_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to="/servicepage" className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="footer-left-heading">
                  {/* Hire dedicated remote <br /> developers in India */}
                  Services
                </div>
                <div className="footer-subcolumn-ul footer-hire-dev">
                  <ul>
                    {center_second_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to="/servicepage" className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {center_third_column.map((item) => (
                      <li className="center-listitems" key={item.id}>
                        <Link to="/servicepage" className="footer-listitems-parent-left">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col footer-contactUs">
            <div className="contact-col">
              <div className="footer-left-heading  ml">Contact Us</div>

              <div className="footer-column-right">
                <div className="skype-grid">
                  <div className="skype-num div-1">
                    <Link to="#">Skype :</Link>
                  </div>

                  <div className="skype-num div-4">
                    <Link to="tel:+1 352 448 6955">+1 352 448 6955</Link>
                  </div>
                </div>

                <div className="email-grid">
                  <div className="phone-num div-1">
                    <Link to="#">Email :</Link>
                  </div>

                  <div className="footer-hr-email div-2">
                    <Link to="mailto:sales@taskcraft.in">
                      sales@taskcraft.in
                    </Link>
                  </div>

                  <div className="footer-hr-email div-3">
                    <Link to="mailto:hr@taskcraft.in">hr@taskcraft.in</Link>
                  </div>

                  {/* <div className="email-grid pmt"> */}
                  <div className="phone-num div-4">
                    <Link to="#">Phone Number:</Link>
                  </div>
                  <div className="footer-hr-email div-5">
                    <Link to="tel:+91-7880001860">+91-7880001860</Link>
                  </div>
                  <div className="footer-hr-email div-6">
                    <Link to="tel:+91-7880001859">+91-7880001859</Link>
                  </div>
                </div>
                {/* </div> */}
              </div>

              <div className="footer-third-column-last pmt">
                <div className="footer-element-divider-column-second">
                  <div className="footer-address ">
                    <div className="for-add-text add">
                      Address : Second Floor, Sky Space, 290-291, PU-4, Vijay
                      Nagar, Indore{" "}
                    </div>
                  </div>
                  <div className="icon-value">
                    <Link
                      to="https://twitter.com/HRTeam40921298"
                      className="twitter-icon"
                    >
                      <RiTwitterXFill size={40} />
                    </Link>
                    <Link
                      to="https://www.instagram.com/taskcraft_technologies/"
                      className="instagram-icon"
                    >
                      <FaInstagramSquare size={40} />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/79373521/admin/feed/posts/"
                      className="linkedin-icon"
                    >
                      <FaLinkedin size={40} />
                    </Link>
                    <Link
                      to="https://www.facebook.com/profile.php?id=100083284865373"
                      className="youtube-icon"
                    >
                      <FaFacebook size={40} />
                    </Link>
                    <Link
                      to="https://join.skype.com/invite/ub91skYE74bA"
                      className="youtube-icon"
                    >
                      <FaSkype size={40} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <img
          height={100}
          style={{ marginRight: 20 }}
          src={iso}
          alt="ISO 27001"
        />
        <img height={100} src={cmmi5} alt="CMMI Level 5" />
      </div>
      <div className="row align-items-center footer-end-content">
        <div className="end-content">
          <div className="copyright-text">
            © 2024 Taskcraft. All Rights Reserved.
          </div>
          {/* <div className="privacy-text">
            <Link to="">Privacy Policy</Link>
            <Link to="">Sitemap</Link>
          </div> */}
        </div>
      </div>
    </section>
  );
};
