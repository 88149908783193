import React from "react";
import "./style.css";

export function TermsandCondition() {
  return (
    <>
      <div className="shadow-box">
        <div className="terms-container">
          <h1>Terms and Conditions</h1>
          <p>Last Updated: 26 Nov 2024</p>
          <p>
            Welcome to TaskCraft Technology. By accessing or using our services,
            you agree to comply with the following Terms and Conditions. Please
            read them carefully. If you do not agree to these terms, you may not
            use our services.
          </p>
          <hr />
          <h2>1. Acceptance of Terms</h2>
          <p>
            By using or accessing TaskCraft Technology’s services, you agree to
            be bound by these Terms and Conditions and any additional
            guidelines, rules, or disclaimers posted on the Service. If you do
            not agree to these terms, please do not use our Service.
          </p>
          <h2>2. Eligibility</h2>
          <p>
            You must be at least 18 years old to use our services. By agreeing
            to these terms, you represent and warrant that you are at least 18
            years of age.
          </p>
          <h2>3. Use of Service</h2>
          <p>
            You agree to use our services only for lawful purposes and in
            accordance with our acceptable use policies. You shall not use the
            service in any way that violates applicable local, national, or
            international laws.
          </p>
          <p>
            <strong>Prohibited Uses:</strong>
          </p>
          <ul>
            <li>
              Interfering with or disrupting the Service or any servers or
              networks connected to the Service.
            </li>
            <li>
              Engaging in any activity that could damage, disable, overburden,
              or impair the Service.
            </li>
            <li>Using the Service for fraudulent or malicious activities.</li>
          </ul>
          <h2>4. User Account</h2>
          <p>
            If you create an account to use the Service, you agree to provide
            accurate, current, and complete information about yourself. You are
            responsible for maintaining the confidentiality of your account and
            password and for all activities that occur under your account.
          </p>
          <h2>5. Privacy Policy</h2>
          <p>
            We value your privacy. Please review our [Privacy Policy] to
            understand how we collect, use, and protect your personal data.
          </p>
          <h2>6. Content Ownership</h2>
          <p>
            All content provided by the Service, including text, graphics,
            logos, images, videos, and other materials, is the property of [Your
            Company Name] or its licensors and is protected by copyright,
            trademark, and other intellectual property laws. You may not
            reproduce, distribute, or create derivative works from any content
            without prior written consent from TaskCraft Technology.
          </p>
          <h2>7. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by applicable law, TaskCraft
            Technology shall not be liable for any indirect, incidental,
            special, or consequential damages resulting from your use of the
            Service, even if we have been advised of the possibility of such
            damages.
          </p>
          <h2>8. Termination</h2>
          <p>
            We may terminate or suspend your access to the Service, without
            prior notice or liability, for any reason, including if you breach
            the Terms and Conditions.
          </p>
          <h2>9. Modifications</h2>
          <p>
            We reserve the right to modify or replace these Terms and Conditions
            at any time. Any changes will be effective when posted on this page.
            It is your responsibility to review these terms periodically to stay
            informed of any updates.
          </p>
          <h2>10. Governing Law</h2>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of [Your Country/State], without regard to
            its conflict of law principles.
          </p>
          <h2>11. Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at:
          </p>
          <p>Email: sales@taskcraft.in</p>
          <p>Phone: +91-7880001860</p>
          <p>
            Address: Second Floor, Sky Space, 290-291, PU-4, Vijay Nagar, Indore
          </p>
          <hr />
          <p>
            By agreeing to these Terms and Conditions, you acknowledge that you
            have read, understood, and accept all the terms outlined above.
          </p>
          <hr />
        </div>
      </div>
    </>
  );
}