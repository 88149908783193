export const routes = {
  general: {
    home: "/",
    hireDevelopers: ":contentId",
    contactUs: "contactus",
    aboutUs: "aboutus",
    careers: "careers",
    ai: "ai",
    Blog: "blogs",
    servicepage: "servicepage",
    events:"events",
    Team: "Team",
    aiml: "aiml",
    ecommerce: "ecommerce",
    nocodelowcode:"nocodelowcode",
    gallery:"gallery"
    
  },
  hireDevelopersRoutes: {
    customApplicationDevelopment: "customApplicationDevelopment",
    customMobileDevelopment: "customMobileDevelopment",
    webDevelopment: "webDevelopment",
    productEngineering: "productEngineering",
    cloudAndInfrastructure: "cloudAndInfrastructure",
    devOpsSolutions: "devOpsSolutions",
    salesforceDevelopment: "salesforceDevelopment",
    managedAWSService: "managedAWSService",
    ERPSolutions: "ERPSolutions",
    onDemandSolutions: "onDemandSolutions",
    mobilitySolutions: "mobilitySolutions",
    staffAugmentation: "staffAugmentation",
    APIDevelopment: "APIDevelopment",
    testingSolutions: "testingSolutions",
    databaseSolutions: "databaseSolutions",
    backEndSolutions: "back-endSolutions",
  },

  PrdouctsRoutes: {
    NoCodeLowCode: "nocodelowcode",
    AIMl: "AIMl",
    Ecommerce: "Ecommerce",
  
  },
};