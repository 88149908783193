import React from "react";
import { Link } from "react-router-dom";

import { routes } from "../../../routes/routes";
import "./SecondHoverContent.css";

export const SecondHoverContent = ({ setShow }) => {
  const hoverContentHandler = () => {
    setShow(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    // <div className="Hovercontent-second-main-div">
    //   <div className="container hovercontent-first-container">
    //     <div className="row">
    //       <div className="col-6 second-hover-content-first-column">
    //         <h5 className="service-modal-heading">Software Development</h5>
    //         <span className="divider-separator"></span>
    //         <Link
    //           to={routes.hireDevelopersRoutes.customApplicationDevelopment}
    //           onClick={hoverContentHandler}
    //         >
    //           Custom Application Development
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.customMobileDevelopment}
    //           onClick={hoverContentHandler}
    //         >
    //           Custom Mobile Development
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.webDevelopment}
    //           onClick={hoverContentHandler}
    //         >
    //           Web Development
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.productEngineering}
    //           onClick={hoverContentHandler}
    //         >
    //           Product Engineering
    //         </Link>
    //       </div>
    //       <div className="col-6 second-hover-content-second-column">
    //         <h5 className="service-modal-heading">Infrastructure Management</h5>
    //         <span className="divider-separator"></span>
    //         <Link
    //           to={routes.hireDevelopersRoutes.cloudAndInfrastructure}
    //           onClick={hoverContentHandler}
    //         >
    //           Cloud & Infrastructure
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.devOpsSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           DevOps Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.salesforceDevelopment}
    //           onClick={hoverContentHandler}
    //         >
    //           Salesforce Development
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.managedAWSService}
    //           onClick={hoverContentHandler}
    //         >
    //           Managed AWS Services
    //         </Link>
    //       </div>
    //       <div className="col-6 second-hover-content-third-column">
    //         <h5 className="service-modal-heading">Enterprise Solutions</h5>
    //         <span className="divider-separator"></span>
    //         <Link
    //           to={routes.hireDevelopersRoutes.ERPSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           ERP Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.onDemandSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           On-demand Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.mobilitySolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           Mobility Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.staffAugmentation}
    //           onClick={hoverContentHandler}
    //         >
    //           Staff Augmentation
    //         </Link>
    //       </div>
    //       <div className="col-6 second-hover-content-fourth-column">
    //         <h5 className="service-modal-heading">Application Services</h5>
    //         <span className="divider-separator"></span>
    //         <Link
    //           to={routes.hireDevelopersRoutes.APIDevelopment}
    //           onClick={hoverContentHandler}
    //         >
    //           API Development
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.testingSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           Testing Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.databaseSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           Database Solutions
    //         </Link>
    //         <Link
    //           to={routes.hireDevelopersRoutes.backEndSolutions}
    //           onClick={hoverContentHandler}
    //         >
    //           Back-End Solutions
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="Hovercontent-second-main-div">
      <div className="container hovercontent-first-container">
        <div className="row" style={{ marginLeft: "3%" }}>
          <div className="col-6 second-hover-content-first-column">
            <Link
              to={routes.PrdouctsRoutes.NoCodeLowCode}
              onClick={hoverContentHandler}
              style={{cursor:"pointer"}}
            >
              {" "}
              <h5 className="service-modal-heading" >
                Less Code Development
              </h5>{" "}
            </Link>
            <span className="divider-separator" ></span>
            No Code Low Code Solutions
          </div>
          <div className="col-6 second-hover-content-second-column">
            <Link to={routes.PrdouctsRoutes.AIMl} onClick={hoverContentHandler}  style={{cursor:"pointer"}}>
              {" "}
              <h5 className="service-modal-heading">
                Machine Learning Solution
              </h5>
            </Link>
            <span className="divider-separator"></span>
            AI & ML Solutions
          </div>
          <div className="col-6 second-hover-content-third-column">
            <Link
              to={routes.PrdouctsRoutes.Ecommerce}
              onClick={hoverContentHandler}
              style={{cursor:"pointer"}}
            >
              {" "}
              <h5 className="service-modal-heading">
                E-commerce Development
              </h5>{" "}
            </Link>
            <span className="divider-separator"></span>
            E-commerce Solutions
          </div>
        </div>
      </div>
    </div>
  );
};
