import React from "react";
import { Link } from "react-router-dom";
import "./Ecom.css";
import { Footer } from "../../container/footerSection";
import { useEffect } from "react";
const NoCodeLowCode = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="ecommerce_container_update"
        style={{ background: "white", paddingTop: "5%" }}
      >
        {/* Development Section */}
        <section className="development_update">
          <div className="development_left_update">
            <img
              src="images/nocodel.jpg"
              alt="No Code/Low Code Development"
              className="development_image_update"
            />
          </div>
          <div className="development_right_update">
            <h1>No Code/Low Code Solutions Development</h1>
            <div className="cards_update">
              <h3>Why No Code/Low Code?</h3>
              <p>
                Accelerate innovation without the need for extensive coding
                expertise. No Code/Low Code platforms empower teams to create
                robust applications quickly, efficiently, and with minimal
                technical barriers.
              </p>
            </div>
            <div className="cards_update">
              <h3>Low-Code Applications</h3>
              <p>
                Streamline your processes and build feature-rich applications
                faster, enabling agility and flexibility in achieving your
                business goals.
              </p>
            </div>
            <div className="cards_update">
              <h3>Empowering Innovation</h3>
              <p>
                Unlock the potential of your team by enabling them to build,
                deploy, and iterate applications quickly with No Code/Low Code
                solutions tailored for your needs.
              </p>
            </div>
          </div>
        </section>

        {/* No Code/Low Code Service Description Section */}
        <section className="service_description">
          <div className="service_description_box">
            <h2>Build Applications with No Code/Low Code in Just 60 Days</h2>
            <p className="intro_text">
              Today’s fast-paced world demands rapid application development.
              With our No Code/Low Code solutions, you can create powerful
              applications faster, with less effort and cost. Whether starting
              from scratch or enhancing existing systems, we deliver solutions
              in under 60 days.
            </p>
            <div className="service-details">
              <div className="service-item">
                <h3>Custom No Code/Low Code Solutions</h3>
                <p>
                  Design and implement tailored solutions to fit your specific
                  business needs, helping you innovate and stay ahead of the
                  competition.
                </p>
              </div>
              <div className="service-item">
                <h3>Streamlined Processes</h3>
                <p>
                  Utilize our solutions to simplify workflows and optimize
                  operations without the overhead of traditional development.
                </p>
              </div>
              <div className="service-item">
                <h3>Efficient Integrations</h3>
                <p>
                  From automation to real-time analytics, we integrate No
                  Code/Low Code solutions seamlessly with your systems to drive
                  efficiency and scalability.
                </p>
              </div>
              <div className="service-item">
                <h3>Scalable Applications</h3>
                <p>
                  Build applications that grow with your business, ensuring they
                  remain relevant and robust as your needs evolve.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Our Services Section */}
        <section
          className="erp-integration"
          style={{ backgroundColor: "white" }}
        >
          <h2>Our Services</h2>
          <h1>No Code/Low Code Development and Integrations</h1>
          <div className="erp-cards">
            <div className="erp-card">
              <img
                src="images/data_analysis.png"
                alt="Visual Workflow Automation"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Visual Workflow Automation</h3>
              <p>
                Simplify your operations with workflows built using intuitive
                drag-and-drop interfaces, making development accessible to
                everyone.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/automation.png"
                alt="Rapid Application Development"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Rapid Application Development</h3>
              <p>
                Build and deploy applications faster with pre-built templates
                and reusable components that save time and resources.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/automation1.png"
                alt="Customizable Dashboards"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Customizable Dashboards</h3>
              <p>
                Create visually appealing dashboards that provide actionable
                insights tailored to your business objectives.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/predictions.png"
                alt="Data Visualization Tools"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Data Visualization Tools</h3>
              <p>
                Transform data into interactive charts, graphs, and reports to
                empower informed decision-making at all levels.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/nlp.png"
                alt="Integration with Legacy Systems"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Integration with Legacy Systems</h3>
              <p>
                Bridge the gap between modern and existing systems to create a
                unified ecosystem that boosts efficiency.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/computer_vision.png"
                alt="Multi-Platform Deployment"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Multi-Platform Deployment</h3>
              <p>
                Develop applications that work seamlessly across devices,
                ensuring a consistent experience for users.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/recommendations.png"
                alt="Pre-Built Connectors"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Pre-Built Connectors</h3>
              <p>
                Leverage an extensive library of connectors to integrate your
                applications with various tools and platforms effortlessly.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/anomaly_detection.png"
                alt="Prototyping Tools"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Prototyping Tools</h3>
              <p>
                Create prototypes and iterate designs quickly to ensure your
                application aligns with your vision and goals.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/digital_twin.png"
                alt="Compliance & Governance"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Compliance & Governance</h3>
              <p>
                Maintain regulatory compliance while building applications,
                ensuring data security and governance.
              </p>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="why-choose-us">
          <h2>Why Choose Us?</h2>
          <div className="why-choose-us-content">
            <div className="why-item">
              <h3>Quick Launch, Maximum Efficiency</h3>
              <p>
                With No Code/Low Code platforms, we ensure rapid deployment of
                solutions tailored to your needs, helping you achieve results in
                record time.
              </p>
            </div>
            <div className="why-item">
              <h3>Tailored Solutions for Your Business</h3>
              <p>
                We customize No Code/Low Code tools to fit your industry,
                ensuring they align perfectly with your goals, data, and unique
                workflows.
              </p>
            </div>
            <div className="why-item">
              <h3>Scalable Applications</h3>
              <p>
                Our solutions are designed to scale with your business, ensuring
                they remain robust and efficient as your needs evolve.
              </p>
            </div>
            <div className="why-item">
              <h3>Cost-Effective Development</h3>
              <p>
                Save on development costs by leveraging No Code/Low Code
                platforms, while still achieving high-quality, fully functional
                solutions.
              </p>
            </div>
            <div className="why-item">
              <h3>User-Friendly Interfaces</h3>
              <p>
                We design intuitive, easy-to-use interfaces that empower your
                team to manage and utilize applications with minimal technical
                training.
              </p>
            </div>
            <div className="why-item">
              <h3>Seamless System Integration</h3>
              <p>
                Integrate No Code/Low Code applications seamlessly with your
                existing tools, CRMs, or databases, ensuring a smooth workflow.
              </p>
            </div>
            <div className="why-item">
              <h3>Ongoing Support</h3>
              <p>
                Our team provides continuous support to ensure your applications
                remain updated and aligned with your evolving business needs.
              </p>
            </div>
            <div className="why-item">
              <h3>Future-Proof Solutions</h3>
              <p>
                We build solutions that are adaptable, ensuring compatibility
                with the latest No Code/Low Code innovations and market trends.
              </p>
            </div>
          </div>
        </section>

        {/* How We Do It Section */}
        <section className="how-we-do-it">
          <h2>How We Do It</h2>
          <div className="how-we-do-it-content">
            <div className="how-item">
              <div className="icon">
                <img src="images/consult.jpg" alt="Consultation Icon" />
              </div>
              <h3>Consultation & Strategy</h3>
              <p>
                We work closely with you to understand your objectives and
                identify the best No Code/Low Code tools to achieve them
                effectively.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/design.jpg" alt="Development Icon" />
              </div>
              <h3>Platform Setup & Customization</h3>
              <p>
                Our team configures and customizes the selected No Code/Low Code
                platforms, ensuring they align with your business processes and
                requirements.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/testing.png" alt="Testing Icon" />
              </div>
              <h3>Testing & Deployment</h3>
              <p>
                We rigorously test your applications to ensure they meet
                performance and reliability standards, then deploy them
                seamlessly into your systems.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/optimize.png" alt="Optimization Icon" />
              </div>
              <h3>Continuous Improvement</h3>
              <p>
                Post-deployment, we monitor and refine your solutions, making
                updates as needed to keep them optimized for your business
                growth.
              </p>
            </div>
          </div>
        </section>

        <section className="our-promise">
          <h2>Our Promise to You</h2>
          <div className="promise-content">
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/fast.png" alt="Fast Delivery Icon" />
              </div>
              <div className="promise-text">
                <h3>Rapid Implementation</h3>
                <p>
                  Launch your No Code/Low Code applications quickly without
                  compromising on quality or functionality.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img
                  src="images/customize.png"
                  alt="Customized Solutions Icon"
                />
              </div>
              <div className="promise-text">
                <h3>Fully Customized Solutions</h3>
                <p>
                  Enjoy applications built specifically for your business, using
                  flexible No Code/Low Code tools.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/end.png" alt="End-to-End Service Icon" />
              </div>
              <div className="promise-text">
                <h3>Comprehensive Support</h3>
                <p>
                  From strategy to deployment and beyond, we’re with you every
                  step of the way to ensure success.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/price.png" alt="Affordable Pricing Icon" />
              </div>
              <div className="promise-text">
                <h3>Affordable Innovation</h3>
                <p>
                  Harness the power of No Code/Low Code development at a
                  fraction of the cost of traditional software solutions.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Ready Section */}
        <section className="ready-wired">
          <div className="ready-wired-content">
            <h1>
              Ready to Build with{" "}
              <span className="wired-highlight">No Code/Low Code?</span>
            </h1>
            <p className="wired-description">
              Whether you're just beginning your journey or enhancing existing
              systems, we’re here to guide you. Get in touch today to discuss
              your project!
            </p>
            <Link to="/contactus">
              <button className="wired-btn">Lets Connect</button>
            </Link>
          </div>
          <div className="ready-wired-image">
            {/* <img src="images/contact.png" alt="Contact Us" /> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default NoCodeLowCode;
