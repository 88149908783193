import { useState } from "react";

export const useForm = (validation) => {
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = validation(value);
  const hasError = !valueIsValid && isTouched;

  const valueChangeHandler = (event) => {
    setValue(event?.target?.value);
  };

  const valueBlurHandler = () => {
    setIsTouched(true);
  };

  const resetValue = () => {
    setIsTouched(false);
    setValue("");
  };

  return {
    value,
    isTouched,
    valueIsValid,
    hasError,
    valueChangeHandler,
    valueBlurHandler,
    resetValue,
  };
};
