import React from "react";
import { Link } from "react-router-dom";
import "./Ecom.css";
import { Footer } from "../../container/footerSection";
import { useEffect } from "react";
import { routes } from "../../routes/routes";
const AIML = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className="ecommerce_container_update"
        style={{ background: "white"}}
      >
        {/* Development Section */}
        <section className="development_update" style={{paddingLeft:"40px",paddingRight:"40px", gap:"2rem"}}>
          <div className="development_left_update">
            <img
              src="images/aifinal2.png"
              alt="AI/ML Development"
              className="development_image_update"
            />
          </div>
          <div className="development_right_update">
            <h1>AI/ML Solutions Development</h1>
            <div className="cards_update">
              <h3>Why AI/ML?</h3>
              <p>
              Step into the future of technology! Embrace the power of AI/ML to unlock endless opportunities for automation, gain game-changing insights, and revolutionize your business operations.
              <br/>👉 <Link to="/ai" style={{textDecoration:"none"}}>Explore How AI Can Transform Your Business</Link>
              </p>
            </div>
            <div className="cards_update">
              <h3>AI/ML-Powered Applications</h3>
              <p>
                Transform your business with intelligent applications designed
                to analyze data, predict trends, and optimize decision-making
                processes.
              </p>
            </div>
            <div className="cards_update">
              <h3>Build Smarter Systems</h3>
              <p>
                Enhance customer experiences and drive growth with tailored
                AI/ML solutions designed to meet the unique needs of your
                industry.
              </p>
            </div>
          </div>
        </section>

        {/* AI/ML Service Description Section */}
        <section className="service_description">
          <div className="service_description_box">
            <h2>
              Transform Your Business with Our AI/ML Solutions in Just 60 Days
            </h2>
            <p className="intro_text">
              In today’s digital era, AI/ML solutions are essential for staying
              competitive. We specialize in building intelligent systems that
              are fast, scalable, and optimized for efficiency. Whether you're
              starting fresh or looking to integrate AI/ML into your existing
              systems, we deliver innovative solutions in under 60 days.
            </p>
            <div className="service-details">
              <div className="service-item">
                <h3>Custom AI/ML Development</h3>
                <p>
                  We design and implement AI models tailored to your specific
                  business needs, helping you unlock new potential and
                  outperform competitors.
                </p>
              </div>
              <div className="service-item">
                <h3>Data-Driven Insights</h3>
                <p>
                  Our solutions help you extract actionable insights from
                  complex datasets, enabling informed decision-making and
                  strategic growth.
                </p>
              </div>
              <div className="service-item">
                <h3>Advanced AI Integrations</h3>
                <p>
                  From predictive analytics to automation tools, we integrate
                  cutting-edge AI technologies to streamline your operations and
                  improve efficiency.
                </p>
              </div>
              <div className="service-item">
                <h3>Scalable Machine Learning Models</h3>
                <p>
                  Build machine learning models designed to adapt and grow with
                  your business, ensuring long-term success and sustainability.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Our Services Section */}
        <section
          className="erp-integration"
          style={{ backgroundColor: "white" }}
        >
          <h2>Our Services</h2>
          <h1>AI/ML Development and Integrations</h1>
          <div className="erp-cards">
            <div className="erp-card">
              <img
                src="images/data_analysis.png"
                alt="Data Analysis and Modeling"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Data Analysis and Modeling</h3>
              <p>
                Unlock the full potential of your data with advanced analysis
                techniques and machine learning models built to deliver results.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/automation.png"
                alt="Process Automation"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Process Automation</h3>
              <p>
                Automate repetitive tasks with AI, improving efficiency and
                freeing up resources for more critical business activities.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/automation1.png"
                alt="AI-Powered Assistants"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>AI-Powered Assistants</h3>
              <p>
                Enhance customer interactions with chatbots and virtual
                assistants that provide intelligent, real-time support.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/predictions.png"
                alt="Predictive Analytics"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Predictive Analytics</h3>
              <p>
                Leverage predictive insights to forecast trends, mitigate risks,
                and make proactive business decisions.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/nlp.png"
                alt="Natural Language Processing"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Natural Language Processing</h3>
              <p>
                Build intelligent systems capable of understanding, analyzing,
                and generating human language for applications like sentiment
                analysis and text summarization.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/computer_vision.png"
                alt="Computer Vision"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Computer Vision</h3>
              <p>
                Implement cutting-edge computer vision solutions to enable
                object detection, facial recognition, and image classification
                for various use cases.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/recommendations.png"
                alt="Recommendation Systems"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Recommendation Systems</h3>
              <p>
                Deliver personalized recommendations to users with AI-driven
                systems that boost engagement and conversion rates.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/anomaly_detection.png"
                alt="Anomaly Detection"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Anomaly Detection</h3>
              <p>
                Detect unusual patterns or outliers in data to proactively
                identify issues and ensure smooth business operations.
              </p>
            </div>
            <div className="erp-card">
              <img
                src="images/digital_twin.png"
                alt="Digital Twin Technology"
                style={{ height: "30%", width: "40%" }}
              />
              <h3>Digital Twin Technology</h3>
              <p>
                Simulate real-world systems with AI-powered digital twins for
                better monitoring, optimization, and decision-making.
              </p>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="why-choose-us">
          <h2>Why Choose Us?</h2>
          <div className="why-choose-us-content">
            <div className="why-item">
              <h3>Quick Launch, Maximum Impact</h3>
              <p>
                We understand the urgency of deploying AI/ML solutions to drive
                innovation. Our streamlined development process ensures that
                your solution is up and running within 60 days, enabling you to
                unlock insights and accelerate decision-making without delay.
              </p>
            </div>
            <div className="why-item">
              <h3>Custom Solutions for Your Unique Needs</h3>
              <p>
                Every organization is unique, and so are its challenges. That’s
                why we offer tailored AI/ML solutions designed specifically for
                your industry, your data, and your goals. From predictive
                analytics to advanced automation, we deliver solutions that make
                a real impact.
              </p>
            </div>
            <div className="why-item">
              <h3>Scalable and Robust Models</h3>
              <p>
                In a fast-changing technological landscape, scalability is key.
                We build AI/ML models that grow with your needs, ensuring robust
                performance whether you’re processing thousands or millions of
                data points.
              </p>
            </div>
            <div className="why-item">
              <h3>Optimized for Maximum Insights</h3>
              <p>
                Our AI/ML solutions are designed to extract actionable insights
                from your data. From uncovering hidden patterns to providing
                real-time analytics, we help you make data-driven decisions that
                drive growth and innovation.
              </p>
            </div>
            <div className="why-item">
              <h3>Secure and Compliant Solutions</h3>
              <p>
                Security and compliance are non-negotiable in AI/ML deployments.
                We ensure that your data is handled securely and in compliance
                with the latest regulations, giving you peace of mind while
                leveraging the power of AI.
              </p>
            </div>
            <div className="why-item">
              <h3>Seamless Integration with Existing Systems</h3>
              <p>
                Our AI/ML solutions integrate seamlessly with your existing
                infrastructure, whether it's a CRM, ERP, or cloud service. This
                ensures a smooth implementation and minimal disruption to your
                workflows.
              </p>
            </div>
            <div className="why-item">
              <h3>Advanced AI/ML Features</h3>
              <p>
                From natural language processing and computer vision to
                predictive modeling and automation, we equip your business with
                cutting-edge AI/ML features that transform your operations and
                customer interactions.
              </p>
            </div>
            <div className="why-item">
              <h3>Support Every Step of the Way</h3>
              <p>
                Our team doesn’t just build your AI/ML solution and walk away.
                We provide ongoing support to ensure your models remain
                accurate, up-to-date, and aligned with your evolving business
                objectives.
              </p>
            </div>
          </div>
        </section>

        <section className="how-we-do-it">
          <h2>How We Do It</h2>
          <div className="how-we-do-it-content">
            <div className="how-item">
              <div className="icon">
                <img src="images/consult.jpg" alt="Consultation Icon" />
              </div>
              <h3>Consultation & Strategy</h3>
              <p>
                We start by understanding your business challenges, data needs,
                and AI/ML goals. Our team collaborates with you to design a
                roadmap that aligns technology with your vision.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/design.jpg" alt="Development Icon" />
              </div>
              <h3>Model Development & Training</h3>
              <p>
                Our experts design and train custom AI/ML models tailored to
                your business requirements. Using cutting-edge tools, we ensure
                your models deliver accurate, actionable insights.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/testing.png" alt="Testing Icon" />
              </div>
              <h3>Testing & Deployment</h3>
              <p>
                Before deployment, we rigorously test models for performance,
                scalability, and accuracy. Once validated, we seamlessly
                integrate the models into your existing systems.
              </p>
            </div>
            <div className="how-item">
              <div className="icon">
                <img src="images/optimize.png" alt="Optimization Icon" />
              </div>
              <h3>Continuous Improvement</h3>
              <p>
                Post-deployment, we monitor performance and refine models to
                adapt to changing data patterns, ensuring sustained efficiency
                and business growth.
              </p>
            </div>
          </div>
        </section>

        {/* Our Promise to You Section */}
        <section className="our-promise">
          <h2>Our Promise to You</h2>
          <div className="promise-content">
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/fast.png" alt="Fast Delivery Icon" />
              </div>
              <div className="promise-text">
                <h3>Timely Delivery</h3>
                <p>
                  Deploy AI/ML solutions in record time, ensuring your business
                  stays ahead in a competitive market.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img
                  src="images/customize.png"
                  alt="Customized Solutions Icon"
                />
              </div>
              <div className="promise-text">
                <h3>Tailored Solutions</h3>
                <p>
                  We create AI/ML models customized to solve your unique
                  business challenges and drive innovation.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/end.png" alt="End-to-End Service Icon" />
              </div>
              <div className="promise-text">
                <h3>End-to-End Support</h3>
                <p>
                  From data strategy to deployment and beyond, we provide
                  comprehensive support to maximize impact.
                </p>
              </div>
            </div>
            <div className="promise-item">
              <div className="icon-circle">
                <img src="images/price.png" alt="Affordable Pricing Icon" />
              </div>
              <div className="promise-text">
                <h3>Cost-Effective Solutions</h3>
                <p>
                  Harness AI/ML technology at competitive pricing without
                  compromising on quality or functionality.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Ready to Get in Touch Section */}
        <section className="ready-wired">
          <div className="ready-wired-content">
            <h1>
              Ready to Embrace <span className="wired-highlight">AI/ML?</span>
            </h1>
            <p className="wired-description">
              Whether you're just beginning your AI journey or looking to
              enhance existing systems, we’re here to guide you. Get in touch
              today to discuss your project!
            </p>
            <span style={{gap: '10px' , display: 'flex'}}>
            <Link to="/contactus">
              <button className="wired-btn">Lets Connect</button>
            </Link>
            <Link to="/ai">
              <button className="wired-btn">Explore Our AI Services</button>
            </Link>
            </span>
        
          </div>
          <div className="ready-wired-image">
            {/* <img src="images/ai_contact.png" alt="Contact Us" /> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AIML;
