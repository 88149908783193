import React from "react";

import { Button } from "../../../components/button";
import "./AboutUsTopSection.css";
import cup from "../../../assets/Untitled design (1) (1).png";
import { useNavigate } from "react-router-dom";


export const AboutUsTopSection = () => {

  const navigate = useNavigate()

  const handleNavigate = () => {
      navigate("/staffAugmentation")
  }

  return (
    <div className="about-us-main-div">
      <div className="container">
        <div className="row main-content-about-us-row" >
          <div className="col-8" style={{marginTop:"5rem"}}>
            <div className="first-column-first-div">
              <h4 className="about-us-heading">"What We Are"</h4>
              <p className="about-us-content" style={{fontSize:"2rem" , lineHeight: 1.5 }}>
              Pioneering the establishment of industry-defining standards   to proper technological innovation, enhance operational efficiency, and ensure scalable solutions. Through the integration of advanced methodologies, we strive to accelerate the evolution of groundbreaking solutions that set new benchmarks for excellence.
              </p>
            </div>
          </div>
          <div className="col-4"  >
            <div className="about-us-second-main" style={{marginTop:"6.25rem"}}>
              <img className="about-us-image" src={cup} alt="this is a cup"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
