import React from "react";
import "./team.css";
import { Footer } from "../../container/footerSection";
import Ankursir from "../../../src/assets/images/Ankur-Singh.jpg";
import Nsir from "../../../src/assets/images/NSir.png";

function Team() {
  const leaders = [
    { name: "Neeraj Chhari", role: "Director", image: Nsir },
    { name: "Ankur Chhari", role: "Director", image: Ankursir },
  ];

  // const teamMembers = [
  //   { name: "Vijay Sahu", role: "Developer", image: "/images/emp.png" },
  //   { name: "Siddhant Pathak", role: "Developer", image: "images/emp.png" },
  //   { name: "Shubham Kumar", role: "Developer", image: "images/emp.png" },
  // ];

  const stats = [
    { percentage: "200%", title: "Hardworking to Achieve Results" },
    { percentage: "300+", title: "Successful Projects" },
    { percentage: "150%", title: "Growth Achieved" },
    { percentage: "200+", title: "Happy Customers" },
    { percentage: "100%", title: "Project Completion Guarantee" },
    { percentage: "100+", title: "Team Members" },
    { percentage: "90%", title: "Repeated Clients" },
    { percentage: "15+", title: "Team Experience" },
  ];

  return (
    <>
      <section className="main-content">
        <div className="team-section">
          <h1 className="section-title">Meet the TaskCraft Team</h1>
          <hr className="section-divider" />

          <h2 className="subsection-title">Leaders</h2>
          <div className="leader-container">
            {leaders.map((leader, index) => (
              <div className="team-card" key={index}>
                <div className="team-image">
                  <img src={leader.image} alt={leader.name} />
                </div>
                <h3>{leader.name}</h3>
                <p>{leader.role}</p>
              </div>
            ))}
          </div>

          {/* <hr className="section-divider" /> */}

          {/* <h2 className="subsection-title">Team Members</h2>
          <div className="team-container">
            {teamMembers.map((member, index) => (
              <div className="team-card" key={index}>
                <div className="team-image">
                  <img src={member.image} alt={member.name} />
                </div>
                <h3>{member.name}</h3>
                <p>{member.role}</p>
              </div>
            ))}
          </div> */}
        </div>

        <div className="awards-section">
          <h1 className="awards-title">Award & Recognitions</h1>
          <div className="awards-container">
            <div className="award-card">
              <div className="award-icon-wrapper">
                <img
                  src="images/dummyawd.jpg"
                  alt="Best Innovation Award"
                  className="award-icon"
                />
              </div>
              <h3 className="award-name">Best Innovation Award</h3>
              <p className="award-description">
                Recognized for outstanding innovation in 2023.
              </p>
            </div>
            <div className="award-card">
              <div className="award-icon-wrapper">
                <img
                  src="images/dummyawd.jpg"
                  alt="Customer Excellence Award"
                  className="award-icon"
                />
              </div>
              <h3 className="award-name">Customer Excellence Award</h3>
              <p className="award-description">
                Awarded for exceptional customer service in 2022.
              </p>
            </div>
            <div className="award-card">
              <div className="award-icon-wrapper">
                <img
                  src="images/dummyawd.jpg"
                  alt="Top Industry Leader"
                  className="award-icon"
                />
              </div>
              <h3 className="award-name">Top Industry Leader</h3>
              <p className="award-description">
                Recognized as a top industry leader in 2021.
              </p>
            </div>
            <div className="award-card">
              <div className="award-icon-wrapper">
                <img
                  src="images/dummyawd.jpg"
                  alt="Sustainability Achievement"
                  className="award-icon"
                />
              </div>
              <h3 className="award-name">Sustainability Achievement</h3>
              <p className="award-description">
                Awarded for sustainable practices in 2020.
              </p>
            </div>
            <div className="award-card">
              <div className="award-icon-wrapper">
                <img
                  src="images/dummyawd.jpg"
                  alt="Fastest Growing Company"
                  className="award-icon"
                />
              </div>
              <h3 className="award-name">Fastest Growing Company</h3>
              <p className="award-description">
                Honored as the fastest growing company in 2019.
              </p>
            </div>
          </div>
        </div>

        <div className="stats-section">
          <h1 className="stats-title">Our Achievements</h1>
          <div className="stats-container">
            {stats.map((stat, index) => (
              <div className="stat-text" key={index}>
                <div className="stat-percentage">{stat.percentage}</div>
                <div className="stat-title">{stat.title}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Team;
